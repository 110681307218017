import React, { useState } from 'react';
import './Home.css';  // Create a CSS file for Home page styling
import missionImage from '../assets/mission_image.jpg';  // Image for the mission statement
import expertiseImage from '../assets/our_expertise.jpg'; // Image for the expertise section

const CollapsibleSection: React.FC<{ title: string; content: JSX.Element }> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-section">
      <h2 onClick={toggleSection} className="collapsible-title">
        {title} <span>{isOpen ? '−' : '+'}</span>
      </h2>
      {isOpen && <div className="collapsible-content">{content}</div>}
    </div>
  );
};

const Home: React.FC = () => {
  return (
    <div className="home-container">
      {/* Mission Statement Section */}
      <section className="mission-section">
        <div className="mission-image-container">
          <img src={missionImage} alt="Mission" className="mission-image" />
        </div>
        <div className="mission-text">
          <h1>Mission Statement</h1>
          <CollapsibleSection
            title="Our Mission"
            content={
              <ul>
                <li>
                  <strong>Empower organizations with ServiceNow:</strong> At Maynit, our mission is to empower organizations to achieve their full potential by leveraging the power of the ServiceNow platform. We are committed to delivering excellence in ServiceNow consulting, implementation, and support, helping our clients streamline operations, enhance efficiency, and drive digital transformation.
                </li>
                <li>
                  <strong>Tailored solutions for client success:</strong> Through our deep expertise, industry knowledge, and collaborative approach, we partner with our clients to understand their unique business challenges and objectives. We provide tailored solutions that not only meet their immediate needs but also set them up for long-term success in a rapidly evolving digital landscape.
                </li>
                <li>
                  <strong>Commitment to integrity and client satisfaction:</strong> At the heart of our mission is a dedication to integrity, transparency, and client satisfaction. We believe in building lasting relationships with our clients, based on trust, respect, and mutual success. We are committed to delivering measurable business value and exceeding expectations with every engagement.
                </li>
              </ul>
            }
          />
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="expertise-section">
        <div className="expertise-image-container">
          <img src={expertiseImage} alt="Expertise" className="expertise-image" />
        </div>
        <div className="expertise-text">
          <h1>Our Expertise</h1>
          <CollapsibleSection
            title="Expertise Details"
            content={
              <ol>
                <li>
                  At Maynit, we specialise in delivering comprehensive consulting and implementation services for ServiceNow, empowering organisations to optimize their Governance, Risk, and Compliance (GRC) processes and streamline IT Operations Management (ITOM) workflows with a team of seasoned experts and a deep understanding of ServiceNow's capabilities, we help our clients harness the full potential of the platform to drive operational excellence and achieve their business objectives.
                </li>
                <li>
                  <strong>Comprehensive Solutions:</strong> We offer end-to-end implementation services across the entire ServiceNow platform, including:
                  <ul>
                    <li>IT Service Management (ITSM)</li>
                    <li>IT Operations Management (ITOM)</li>
                    <li>IT Business Management (ITBM)</li>
                    <li>Security Operations</li>
                    <li>Customer Service Management (CSM)</li>
                    <li>Human Resources Service Delivery (HRSD)</li>
                    <li>Governance, Risk, and Compliance (GRC)</li>
                    <li>And more.</li>
                  </ul>
                </li>
              </ol>
            }
          />
        </div>
      </section>
    </div>
  );
};

export default Home;
