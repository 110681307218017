import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import AppRoutes from './routes';
import Banner from './components/Banner'; // Import the Banner component

function App() {
  return (
    <div className="App">
      <Banner /> {/* Include the Banner component here */}
      <NavBar />
      <AppRoutes />
    </div>
  );
}

export default App;