import React from 'react';
import './Banner.css'; // Create a CSS file for styling the banner

const Banner: React.FC = () => {
  return (
    <div className="banner">
      <p>This site is under construction</p>
    </div>
  );
};

export default Banner;
